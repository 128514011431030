@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 60 9% 98%;
    --foreground: 240 10% 4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 176 61% 19%;
    --primary-foreground: 0 0% 100%;

    --secondary: 240 5% 96%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 240 4% 46%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 1rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}
 
@layer base {
  @keyframes scale {
    0%,
    100% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.15);
    }
  }
  * {
    @apply border-border;
  }
  html {
    -webkit-tap-highlight-color: transparent;
  }
  body {
    @apply bg-white text-foreground overflow-x-hidden;
    scrollbar-gutter: stable both-edges;
  }
  .bottom-nav {
    backdrop-filter: blur(8px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 4px);
  }
  .bottom-nav-active-element {
    @apply relative;
    background: radial-gradient(43.75% 43.75% at 50.89% 0%, rgba(144, 255, 148, 0.35) 0%, rgba(255, 255, 255, 0.00) 100%);
  }

  .bottom-nav-active-element::before {
    content: '';
    height: 3px;
    background: linear-gradient(270deg, rgba(113, 219, 163, 0.00) 0%, #71DBA3 50.27%, rgba(113, 219, 163, 0.00) 101.56%);
    @apply absolute top-0 left-0 w-full border-0 rounded-b-sm;
  }
  .markdown > p {
    @apply mb-4;
  }
  .markdown > ol {
    @apply list-decimal list-inside mb-1 mt-2;
  }
  .markdown ul {
    @apply list-disc list-outside pl-2;
  }
  .markdown > ul ul {
    @apply list-[circle] list-outside pl-2;
  }
  .markdown > ol > li {
    @apply mb-4;
  }
  .markdown > ul > li {
    @apply mb-4;
  }
}